import React from "react";

export const NewDeviceIcon = (props) => {
  const { className = "", style = {}, disabled = false, onClick } = props;
  return (
    <svg
      className={className}
      style={style}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_17603_38068)">
        <path
          d="M22.2621 10.921C24.1507 10.921 25.6949 12.4652 25.6949 14.3538C25.6949 16.2424 24.1507 17.7866 22.2621 17.7866H22.116V18.5274C22.116 18.8404 21.8656 19.0909 21.5526 19.0909H8.40568C8.09265 19.0909 7.84224 18.8404 7.84224 18.5274V17.7866H7.7379C5.84933 17.7866 4.30509 16.2424 4.30509 14.3538C4.30509 12.4652 5.84933 10.921 7.7379 10.921H22.2621ZM22.2621 9.1159H7.7379C4.84766 9.1159 2.5 11.4636 2.5 14.3538C2.5 16.7015 4.05467 18.6944 6.18322 19.3517C6.51711 20.249 7.38314 20.8855 8.39524 20.8855H21.5526C22.5543 20.8855 23.4203 20.249 23.7646 19.3621C25.9245 18.7152 27.5 16.7119 27.5 14.3434C27.5 11.4531 25.1523 9.10547 22.2621 9.10547V9.1159Z"
          fill="#113E57"
        />
        <path
          d="M5.68359 14.4154C5.68359 13.1111 6.74787 12.0469 8.05212 12.0469H16.7959C18.1001 12.0469 19.1644 13.1111 19.1644 14.4154C19.1644 15.7197 18.1001 16.7839 16.7959 16.7839H8.05212C6.74787 16.7839 5.68359 15.7197 5.68359 14.4154ZM16.7228 14.3737C16.7228 13.4868 15.9924 12.7564 15.1055 12.7564H11.871C10.9841 12.7564 10.2537 13.4868 10.2537 14.3737C10.2537 15.2606 10.9841 15.9909 11.871 15.9909H15.1055C15.9924 15.9909 16.7228 15.2606 16.7228 14.3737ZM7.90605 16.0223C8.81381 16.0223 9.54419 15.2814 9.54419 14.3841C9.54419 13.4868 8.80338 12.746 7.90605 12.746C7.00872 12.746 6.2679 13.4868 6.2679 14.3841C6.2679 15.2814 7.00872 16.0223 7.90605 16.0223Z"
          fill="#113E57"
        />
      </g>
      <defs>
        <clipPath id="clip0_17603_38068">
          <rect
            width="25"
            height="11.7696"
            fill="white"
            transform="translate(2.5 9.11523)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
