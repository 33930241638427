import React from "react";

export const RCVolumeUp = (props) => {
  const { className = "", style = {}, env = false, isSelected } = props;

  return (
    <svg
      className={`${className} ${isSelected ? "selected" : ""}`}
      style={style}
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_18891_160226)">
        <path
          d="M23.5 46.0027C11.0978 46.0027 1.01196 35.9028 1.01196 23.4994C1.01196 11.096 11.0978 0.996094 23.5 0.996094C35.9021 0.996094 45.988 11.0849 45.988 23.4994C45.988 35.9139 35.9021 46.0027 23.5 46.0027Z"
          fill="#1C1C1C"
        />
        <path d="M23.5 1.9934C35.3512 1.9934 44.9761 11.6171 44.9761 23.5C44.9761 35.3829 35.3512 45.0066 23.5 45.0066C11.6488 45.0066 2.02392 35.3718 2.02392 23.5C2.02392 11.6282 11.6376 1.9934 23.5 1.9934ZM23.5 0C10.5469 0 0 10.5429 0 23.5C0 36.4571 10.5469 47 23.5 47C36.4531 47 47 36.4571 47 23.5C47 10.5429 36.4644 0 23.5 0Z" />
        <path
          d="M16.4726 19.1713C15.7867 19.7804 15.2245 20.1459 15.2245 19.9909C15.2245 19.8358 14.5273 19.7251 13.6728 19.7251H11.6489C11.2328 19.7251 10.8843 20.1348 10.8843 20.6442V26.5802C10.8843 27.0896 11.2328 27.4993 11.6489 27.4993H13.6728C14.5273 27.4993 15.2245 27.455 15.2245 27.3997C15.2245 27.3443 15.7867 27.7983 16.4726 28.3964L20.0144 31.5304C20.7228 32.1506 21.7235 31.5526 21.7235 30.5005V17.0672C21.7235 16.0151 20.7228 15.406 20.0144 16.0373L16.4726 19.1713Z"
          fill="white"
        />
        <path
          d="M25.2542 25.8271C25.9288 25.2623 26.3673 24.4427 26.3673 23.5014C26.3673 22.5601 25.9288 21.7406 25.2542 21.1758"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.571 29.0132C30.0665 27.6289 30.9998 25.6688 30.9998 23.4871C30.9998 21.3054 30.0553 19.3563 28.571 17.9609"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.8992 32.2151C34.2042 30.0002 35.6434 26.9104 35.6434 23.4884C35.6434 20.0664 34.2042 16.9766 31.8992 14.7617"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18891_160226">
          <rect width="47" height="47" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
