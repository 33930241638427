import React from "react";
import { DeviceUnpairIcon } from "../Common/Icons";
import { useUserRole, hasStaffPrivileges } from "./helpers";
import EM200icon from "../Common/Icons/EM200icon.svg";
import { Popconfirm } from "antd";
import { getConfirmationMessage } from "./DeviceInventoryModal";

const EM200ModalInfo = ({
  device,
  performAction,
  actionsData,
  hubRecord,
  hideModal,
  showModal,
  ConfirmationModal,
  isActionModalVisible,
}) => {
  const userRole = useUserRole();

  const handleAction = (device) => {
    let data = {
      ...actionsData.unpair,
      deviceUuid: hubRecord?.deviceuuid,
    };
    const params =
      actionsData.unpair.action === "perform_unPairAccessories"
        ? {
            params: [
              { name: "devicemodel", value: device?.modelnumber },
              { name: "macaddress", value: device?.basemacaddress },
            ],
          }
        : {};
    performAction(data, params);
    hideModal("unpair_em200");
  };

  return (
    <>
      {hasStaffPrivileges(userRole) && device?.paired_status !== "UNPAIRED" && (
        <div className="unpair-actions-container">
          <div className="unpair-block">
            <div className="container">
              <div className="action-label">Actions</div>
              <div className="action-description">
                <div className="unpair-description-label">
                  {`You can unpair the sensor by clicking on the `}
                  <span className="unpair-button">unpair button.</span>
                </div>
              </div>
            </div>
            <div className="unpair-container">
              <div
                className="unpair-icon-label"
                onClick={() => showModal("unpair_em200")}
              >
                <DeviceUnpairIcon
                  className="unpair-icon"
                  strokeColor="#005d98"
                  fillColor="#0072BC"
                />
                <div className="unpair-label">Unpair</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="em200-device-info-container">
        <div className="em200-block">
          <div className="em200-label">
            HomeSight Environmental Sensor (Model {device?.device_model_name ? device?.device_model_name : "-"})
          </div>
          <div className="em200-device-icon">
            <img src={EM200icon} />
          </div>
        </div>
      </div>

      <ConfirmationModal
        visible={isActionModalVisible.unpair_em200}
        title={
          <span className="custom-modal-title">
            {getConfirmationMessage(actionsData.unpair.title)}
          </span>
        }
        description={actionsData.unpair.description}
        onConfirm={() => handleAction(device)}
        onCancel={() => hideModal("unpair_em200")}
      />
    </>
  );
};

export default EM200ModalInfo;
