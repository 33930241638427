export const postalCodeStatus = {
  empty: "EMPTY",
  invalid: "INVALID",
  valid: "VALID",
  noLocationKey: "No location key",
};

export const convertTemperature = (value, currentUnit, targetUnit) => {
  if (value === undefined) return null;
  if (currentUnit === targetUnit) return value;
  const toCelsius = (fahrenheit) => ((fahrenheit - 32) * 5) / 9;
  const toFahrenheit = (celsius) => (celsius * 9) / 5 + 32;

  return targetUnit === "C" ? toCelsius(value) : toFahrenheit(value);
};

export const sortByModelAndLocation = (a, b) => {
  const locationA = a[1]?.location ?? "";
  const locationB = b[1]?.location ?? "";

  if (locationA === null || locationA === undefined) return -1;
  if (locationB === null || locationB === undefined) return 1;

  return locationA.localeCompare(locationB);
};

export const mapEventData = (eventData) => ({
  batteryLevel: eventData.battery_level,
  temperature: eventData.environment_status__temperature,
  tempUnit: eventData.environment_status__temperature_unit,
  humidity: eventData.environment_status__humidity,
  connectionStatus: eventData.connected ? "CONNECTED" : "DISCONNECTED",
  ambientLight: eventData.environment_status__ambient_light,
  location: eventData.location,
  timeStamp: eventData.utc_timestamp_inms,
  deviceId: eventData.environment_status__serial_number,
  modelNumber: eventData.modelnumber,
});

export const filterEventsData = (eventsData) =>
  eventsData.filter((event) => event.modelnumber !== "HC200");

export const sortEventsData = (filteredEventsData) =>
  filteredEventsData.sort((a, b) => {
    const dateA = new Date(a.utc_timestamp_inms);
    const dateB = new Date(b.utc_timestamp_inms);
    return dateB - dateA;
  });

export const determineTempTrend = (sortedEventsData) => {
  if (sortedEventsData.length > 1) {
    const tempCurrent = sortedEventsData[0].environment_status__temperature;
    const tempPrevious = sortedEventsData[1].environment_status__temperature;
    return tempCurrent > tempPrevious ? "up" : "down";
  }
  return null;
};

export const checkAllDevicesProcessed = (
  updatedData,
  userAccessoriesDeviceuuid
) =>
  Object.keys(userAccessoriesDeviceuuid).every((deviceUuid) =>
    Object.keys(updatedData).includes(deviceUuid)
  );

export const processWeatherData = (data, tempUnit, setOutdoorWeather) => {
  if (!data.length) return;
  const weatherData = data[0];
  console.log("Weather Data", weatherData);

  const isFahrenheit = tempUnit === "F";
  const temperature = isFahrenheit
    ? Math.floor(weatherData.temperature.imperial.value)
    : Math.floor(weatherData.temperature.metric.value);
  const unit = isFahrenheit ? "F" : "C";

  setOutdoorWeather({
    temp: temperature,
    unit: unit,
    prec: weatherData.hasprecipitation,
    icon: weatherData.weathericon,
    humidity: weatherData.relativehumidity,
    time: weatherData.localobservationdatetime,
  });
};
