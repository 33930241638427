import { v4 as uuidv4 } from "uuid";
import SessionStorage from "@commscopemycloud/humaui/Utilities/SessionStorage";
import { AppStorageKeys } from "@commscopemycloud/humaui/Utilities/Constants";

const ENV_VARS = SessionStorage.get(AppStorageKeys.envVars);
const VERSION = ENV_VARS.VERSION;
const USER_AGENT = navigator.userAgent;

// General functions
const generateTelemetryEvent = (eventType, eventPayload) => {
  return {
    message_type: eventType,
    message_id: uuidv4(),
    utc_timestamp_inms: Date.now(),
    source: USER_AGENT,
    app_version: VERSION,
    [eventType]: eventPayload,
  };
};

const sendTelemetryEvent = (sendMessage, eventType, eventPayload) => {
  const telemetryEvent = generateTelemetryEvent(eventType, eventPayload);
  sendMessage(telemetryEvent, "message", "events");
};

// Video call attendee info functions
export const sendAttendeeInfo = (sendMessage, attendeeInfoPayload) => {
  sendTelemetryEvent(
    sendMessage,
    "videocall_attendeeinfo_event",
    attendeeInfoPayload
  );
};

// Video call metrics functions
const getChimeMetrics = async (clientMetricReport) => {
  const stats = clientMetricReport.getRTCStatsReport();
  const metrics = {
    audioReceivePacketLossPercent: null,
    audioSendPacketLossPercent: null,
    videoAvailableSendBandwidth: null,
    videoAvailableReceiveBandwidth: null,
    videoSendBitrate: null,
    videoSendFps: null,
  };

  if (stats) {
    stats.forEach((report) => {
      if (report.type === "inbound-rtp" && report.kind === "audio") {
        metrics.audioReceivePacketLossPercent =
          report.packetsReceived > 0
            ? (report.packetsLost / report.packetsReceived) * 100
            : null;
        metrics.audioReceivePacketLossPercent = isNaN(
          metrics.audioReceivePacketLossPercent
        )
          ? null
          : metrics.audioReceivePacketLossPercent;
      }
      if (report.type === "outbound-rtp" && report.kind === "audio") {
        metrics.audioSendPacketLossPercent =
          report.packetsSent > 0
            ? (report.packetsLost / report.packetsSent) * 100
            : null;
        metrics.audioSendPacketLossPercent = isNaN(
          metrics.audioSendPacketLossPercent
        )
          ? null
          : metrics.audioSendPacketLossPercent;
      }
      if (report.type === "candidate-pair" && report.state === "succeeded") {
        metrics.videoAvailableSendBandwidth =
          report.availableOutgoingBitrate || null;
        metrics.videoAvailableReceiveBandwidth =
          report.availableIncomingBitrate || null;
      }
      if (report.type === "outbound-rtp" && report.kind === "video") {
        metrics.videoSendBitrate =
          (report.bytesSent * 8) / report.timestamp || null;
        metrics.videoSendBitrate = isNaN(metrics.videoSendBitrate)
          ? null
          : metrics.videoSendBitrate;
        metrics.videoSendFps = report.framesPerSecond || null;
      }
    });
  }

  return metrics;
};

const constructMetricsPayload = (metrics) => {
  return {
    audio_receive_packet_loss_percent: metrics.audioReceivePacketLossPercent,
    audio_send_packet_loss_percent: metrics.audioSendPacketLossPercent,
    video_available_send_bandwidth: metrics.videoAvailableSendBandwidth,
    video_available_receive_bandwidth: metrics.videoAvailableReceiveBandwidth,
    video_send_bitrate: metrics.videoSendBitrate,
    video_send_fps: metrics.videoSendFps,
  };
};

const sendVideoCallMetricsEvent = (sendMessage, metricsPayload) => {
  sendTelemetryEvent(sendMessage, "videocall_metrics_event", metricsPayload);
};

export const sendMetricsInfo = (audioVideo, sendMessage) => {
  audioVideo.audioVideoController.addObserver({
    metricsDidReceive: (clientMetricReport) => {
      const fetchAndSendMetrics = async () => {
        try {
          const metrics = await getChimeMetrics(clientMetricReport);
          const payload = constructMetricsPayload(metrics);
          sendVideoCallMetricsEvent(sendMessage, payload);
        } catch (error) {
          console.error("Error fetching metrics:", error);
        }
      };

      fetchAndSendMetrics();
    },
  });
};

// Video call info functions
const getChimeInfo = async (audioVideo) => {
  if (!audioVideo) {
    throw new Error("audioVideo object is required");
  }

  try {
    const audioInputDevices = await audioVideo.listAudioInputDevices();
    const videoInputDevices = await audioVideo.listVideoInputDevices();
    const audio_streamtype =
      audioInputDevices.length > 0 ? "VoiceCall" : "None";
    const audio_mode = audioInputDevices.some((device) =>
      device.label.includes("Stereo")
    )
      ? "Stereo48K"
      : "Mono";

    const selfview_onoff = audioVideo.getLocalVideoTile() !== null;
    const mic_onoff = !audioVideo.realtimeIsLocalAudioMuted();
    const audio_session_status = audioVideo.realtimeIsLocalAudioMuted()
      ? "Muted"
      : "Started";

    const videoQualitySettings = audioVideo.getVideoInputQualitySettings();
    const videoencoding_resolution = videoQualitySettings
      ? `${videoQualitySettings.videoWidth}x${videoQualitySettings.videoHeight}p${videoQualitySettings.videoFrameRate}`
      : "Unknown";
    const video_session_status = audioVideo.hasStartedLocalVideoTile()
      ? "Started"
      : "Stopped";

    return {
      audio_streamtype,
      audio_mode,
      selfview_onoff,
      mic_onoff,
      audio_session_status,
      videoencoding_resolution,
      video_session_status,
    };
  } catch (error) {
    console.error("Error getting Chime info:", error);
    throw error;
  }
};

const constructInfoPayload = (info) => {
  return {
    audio_streamtype: info.audio_streamtype,
    audio_mode: info.audio_mode,
    selfview_onoff: info.selfview_onoff,
    mic_onoff: info.mic_onoff,
    audio_session_status: info.audio_session_status,
    videoencoding_resolution: info.videoencoding_resolution,
    video_session_status: info.video_session_status,
  };
};

const sendVideoCallInfoEvent = (sendMessage, infoPayload) => {
  sendTelemetryEvent(sendMessage, "videocall_info_event", infoPayload);
};

export const sendCallInfo = async (audioVideo, sendMessage) => {
  const info = await getChimeInfo(audioVideo);
  const payload = constructInfoPayload(info);
  sendVideoCallInfoEvent(sendMessage, payload);
};
