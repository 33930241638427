/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { AppStore, GooglePlay, ProviderKey, HomeSightLogoKey } from "../Common/Icons";

const MobileAppCardInfo = ({isDataFetched}) => {
    const trans = useSelector(translator);

    return (
        <div>
            <h1 className="info-header">Mobile App</h1>
            <div className="mobile-data-group">
              < HomeSightLogoKey  className="hslogo"/>
              <p className="mobile-info-head">Download the HomeSight Mobile app</p>
            </div>
            <div className="mobile-data-group">
              <p className="mobile-info-code">Provider Code</p>
            </div>
            <div className="mobile-data-group">
              <ProviderKey /> <p>{isDataFetched?.providerPIN}</p>
            </div>
            <div className="mobile-data-store">
              <a href="https://apps.apple.com/us/app/homesight/id6469586386" target="_blank" rel="noopener noreferrer">
              <AppStore />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.homesight.mobile" target="_blank" rel="noopener noreferrer">
              <GooglePlay />
              </a>
            </div>
        </div>
    );
};

export default MobileAppCardInfo;