/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { message } from "antd";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import VideoCallComponent from "./VideoCall.component";
import {
  useAudioVideo,
  useMeetingManager,
  useRosterState,
} from "amazon-chime-sdk-component-library-react";
import { AudioProfile, MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import {
  AppStorageKeys,
  CallEndStatus,
  CallFailStatus,
  CallSuccessStatus,
  DateTimeFormat,
  VideoCallInactiveScreenDismissTimeout,
  VideoCallStatus,
  VideoCallingTimeout,
  VideoCallStatusUpdate,
  VideoRingingTimeout,
} from "@commscopemycloud/humaui/Utilities/Constants";
import {
  decodeExternalUserId,
  getUsername,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import {
  getMeetingId,
  updateInviteStatus,
  updateInvites,
  updateMeetingInfo,
  updateVideoCallState,
} from "@commscopemycloud/humaui/Store/videoCallStore";
import {
  formatInviteList,
  handleVideoCallAvail,
  handleVideoCallClose,
  handleVideoCallEnd,
  handleVideoCallJoin,
  handleVideoCallLeave,
  handleVideoCallMissed,
  sendVideoCallStatus,
} from "@commscopemycloud/humaui/Services/VideoCallService";
import { getMeetingInfo } from "@commscopemycloud/humaui/Store/videoCallStore";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { useWebSocketContext } from "../WebSocket/WebsocketContext";
import SessionStorage from "@commscopemycloud/humaui/Utilities/SessionStorage";
import callertone from "../../../static/audio/callertone.mp3";
import { DashboardIcon } from "../Common/Icons";
import { getCallAutoAccept } from "@commscopemycloud/humaui/Store/videoCallStore";

const ENV_VARS = SessionStorage.get(AppStorageKeys.envVars);
const CHIME_RECONNECT_TIMEOUT_SECONDS =
  ENV_VARS.CHIME_RECONNECT_TIMEOUT_SECONDS;

const audio = new Audio(callertone);
audio.loop = true;

/* defines video parameters for number of users */
const VideoSettings = Object.freeze([
  { users: 5, width: 320, height: 180, frameRate: 30, bitRate: 400 }, // number of participants > 5
  { users: 3, width: 640, height: 360, frameRate: 30, bitRate: 600 }, // number of participants > 3
  { users: 2, width: 960, height: 540, frameRate: 30, bitRate: 800 }, // number of participants > 2
  { users: 0, width: 1280, height: 720, frameRate: 30, bitRate: 1400 }, // number of participants <=2
]);

/* get which video settings applies based on number of users */
const getVideoSettingsIndex = (users) => {
  for (let index in VideoSettings) {
    const settings = VideoSettings[index];
    if (users > settings.users) {
      return index;
    }
  }
  return VideoSettings.length - 1;
};

const updateState = (props) => (s) => {
  return { ...s, ...props };
};

const getInitialInviteList = (caller, callees) => {
  const callerName = getUsername(caller, { nameExt: " (You)" });
  const calleeList = formatInviteList(callees);
  const callerList = formatInviteList(
    [{ ...caller, name: callerName }],
    VideoCallStatus.incall
  );
  return callerList.concat(calleeList);
};

let calling_timers = {};
let ringing_timers = {};

const VideoCall = ({ autoCloseTimer, setAutoCloseTimer }) => {
  const dispatch = useDispatch();
  const trans = useSelector(translator);
  const videoCallApi = useSelector((state) => state.apis.videoCallApi);

  const header = [
    {
      label: trans("DASHBOARD"),
      icon: <DashboardIcon />,
    },
    { label: trans("HOMESIGHTCALL") },
  ];

  const audioVideo = useAudioVideo();
  const { roster } = useRosterState();
  const meetingManager = useMeetingManager();
  const { sendMessage, sendMetricsInfo, sendCallInfo, sendAttendeeInfo } =
    useWebSocketContext();

  const currentUser = useSelector(getCurrentUser);
  const meeting_id = useSelector(getMeetingId);
  const callees = useSelector((state) => state.videoCall.callees);
  const invites = useSelector((state) => state.videoCall.invites);
  const eventInfo = useSelector(getMeetingInfo)?.eventInfo;
  const autoaccept = useSelector(getCallAutoAccept);

  const isEventCall = useMemo(() => !!eventInfo, [eventInfo]);
  const attendees = useMemo(() => Object.values(roster), [roster]);

  /* video-call related variables */
  const [isCallActive, setIsCallActive] = useState(false);
  const [isSetupDone, setIsSetupDone] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState({});
  const [callStatus, setCallStatus] = useState(
    meeting_id ? VideoCallStatus.accepted : VideoCallStatus.calling
  );
  const [currentVideoSettingsIndex, setCurrentVideoSettingsIndex] =
    useState(null);
  const [participantsMuteStatus, setParticipantsMuteStatus] = useState({});

  /** hooks */
  useEffect(() => {
    try {
      const initInvites = getInitialInviteList(currentUser, callees);
      dispatch(updateVideoCallState({ invites: initInvites }));
      calling_timers = {};
      ringing_timers = {};
      initVideoCall();
      return async () => {
        await meetingManager?.leave();
        Object.values(calling_timers).forEach((tid) => clearTimeout(tid));
        Object.values(ringing_timers).forEach((tid) => clearTimeout(tid));
      };
    } catch (error) {}
  }, []);

  useEffect(() => {
    /* HOMESIGHT-3795: disable audio redundancy  */
    audioVideo?.setAudioProfile(new AudioProfile(null, true));

    audioVideo?.realtimeController?.realtimeSubscribeToAttendeeIdPresence(
      onAttendeeChange
    );
    return () =>
      audioVideo?.realtimeController?.realtimeUnsubscribeToAttendeeIdPresence(
        onAttendeeChange
      );
  }, [audioVideo]);

  useEffect(() => {
    /* HOMESIGHT-4103: adapt video encoding based on number of participants */
    const settingsIndex = getVideoSettingsIndex(attendees?.length);
    if (settingsIndex !== currentVideoSettingsIndex && audioVideo) {
      setCurrentVideoSettingsIndex(settingsIndex);
      const settings = VideoSettings[settingsIndex];
      console.debug(
        `Number of participants: ${attendees.length}`,
        `Video Settings changed to: `,
        settings
      );
      audioVideo?.chooseVideoInputQuality(
        settings.width,
        settings.height,
        settings.frameRate
      );
      audioVideo?.setVideoMaxBandwidthKbps(settings.bitRate);
      sendMetricsInfo(audioVideo);
    }
  }, [audioVideo, attendees.length]);

  useEffect(() => {
    if (attendees.length > 1) {
      setIsCallActive(true);
      sendAttendeeInfo({
        attendeeinfo: JSON.stringify(
          attendees.map((attendee) => ({
            attendeeId: attendee?.chimeAttendeeId,
            externalUserId: attendee?.externalUserId,
          }))
        ),
      });
    }
  }, [attendees.length]);

  useEffect(() => {
    if (attendees.length <= 1 && isCallActive && !isEventCall) {
      setCallStatus(VideoCallStatus.leaving);
      dispatch(updateMeetingInfo({ callStatus: VideoCallStatus.leaving }));
    }
  }, [attendees.length, isCallActive]);

  /* call status handling */
  useEffect(() => {
    if (!invites) return;
    if (invites.length === 2) {
      const { useruuid } = currentUser;
      let otherUser = invites.filter((u) => u.useruuid !== useruuid);
      otherUser = (otherUser || [])[0];
      if (otherUser && !CallEndStatus.includes(callStatus)) {
        setCallStatus(otherUser.status);
        dispatch(updateMeetingInfo({ callStatus: otherUser.status }));
      }
    } else {
      if (invites.length) {
        setCallStatus(VideoCallStatus.accepted);
        dispatch(updateMeetingInfo({ callStatus: VideoCallStatus.incall }));
      }
    }
  }, [invites]);

  /* calling/ringing timeout handling */
  useEffect(() => {
    invites.forEach((i) => {
      if (i.status === VideoCallStatus.calling) {
        if (calling_timers[i.useruuid] == null && meeting_id) {
          calling_timers[i.useruuid] = setTimeout(() => {
            handleTimerExpiration(i, i.status);
          }, VideoCallingTimeout * 1000);
        }
      } else if (i.status === VideoCallStatus.ringing) {
        clearTimeout(calling_timers[i.useruuid]);
        delete calling_timers[i.useruuid];
        if (ringing_timers[i.useruuid] == null) {
          ringing_timers[i.useruuid] = setTimeout(() => {
            handleTimerExpiration(i, i.status);
          }, VideoRingingTimeout * 1000);
        }
      } else {
        clearTimeout(calling_timers[i.useruuid]);
        clearTimeout(ringing_timers[i.useruuid]);
        delete calling_timers[i.useruuid];
        delete ringing_timers[i.useruuid];
      }
    });
  }, [invites, meeting_id]);

  /* call start time capturing */
  useEffect(() => {
    if (isSetupDone && !meetingInfo?.startTime) {
      const startTime = moment().format(DateTimeFormat);
      // dispatch(updateMeetingInfo({ startTime }));
      setMeetingInfo(updateState({ startTime }));
      sendCallInfo(audioVideo);
    }
  }, [isSetupDone]);

  useEffect(() => {
    if (CallSuccessStatus.includes(callStatus)) {
      !isSetupDone && joinVideoCall();
    }
    try {
      if (callStatus === VideoCallStatus.ringing) {
        audio?.play();
      } else {
        audio?.pause();
      }
    } catch (error) {
      console.error("Caller Tune Error:", error);
    }
    if (callStatus === VideoCallStatus.leaving) {
      onEndCall({ leaveCall: true });
    }
    /* auto-close for non-active call screens */
    if (
      meeting_id &&
      (CallFailStatus.includes(callStatus) ||
        CallEndStatus.includes(callStatus))
    ) {
      if (CallEndStatus.includes(callStatus)) {
        handleVideoCallLeave({ sendMessage, meeting_id });
      }
      handleVideoCallAvail({ sendMessage, meeting_id });
      console.debug(
        `Auto close timer set... ${autoCloseTimer}`,
        `Status: ${callStatus}`
      );
      const timer = setTimeout(() => {
        console.debug("Auto closing the video call... Status:", callStatus);
        handleVideoCallClose({ dispatch });
      }, VideoCallInactiveScreenDismissTimeout * 1000);
      setAutoCloseTimer(timer);
    } else {
      clearTimeout(autoCloseTimer);
      console.debug(
        `Auto close timer cleared... ${autoCloseTimer}`,
        `Status: ${callStatus}`
      );
    }
    return () => {
      audio?.pause();
      clearTimeout(autoCloseTimer);
    };
  }, [isSetupDone, callStatus]);

  /** ===== video call handler functions ===== */
  /* video call intial setup */
  const initVideoCall = async () => {
    try {
      let meet_id = meeting_id;
      if (!meet_id) {
        const session = await createSession();
        meet_id = session?.meeting_id;
        if (!meet_id) {
          console.error("Error creating session, meeting_id not found!");
          return;
        }
        dispatch(updateMeetingInfo({ meeting_id: meet_id }));
        sendVideoCallStatus({
          sendMessage,
          meeting_id: meet_id,
          userList: callees,
          status: VideoCallStatusUpdate.calling,
        });
      }
    } catch (error) {
      message.error("Error starting meeting!");
      console.error("Error starting meeting!", error);
    }
  };

  const joinVideoCall = async () => {
    try {
      const data = await joinSession(meeting_id);
      const { Meeting, Attendee } = data?.chime || {};
      if (!Meeting || !Attendee) {
        console.error("Error joining meeting! No session generated!");
        return;
      }
      setMeetingInfo(updateState({ Meeting, Attendee }));
      const meetConf = new MeetingSessionConfiguration(Meeting, Attendee);
      meetConf.reconnectTimeoutMs = CHIME_RECONNECT_TIMEOUT_SECONDS * 1000;
      if (!CallEndStatus.includes(callStatus)) {
        handleVideoCallJoin({ sendMessage, meeting_id });
        await meetingManager.join(meetConf);
        await meetingManager.start();
      }
      setIsSetupDone(true);
    } catch (error) {
      message.error("Error joining meeting!");
      console.error("Error joining meeting!", error);
    }
  };

  /* calling/ringing timeout handler */
  const handleTimerExpiration = (invitee, status) => {
    console.debug(`Timeout for status: ${status} for invitee:`, invitee);
    if (
      status === VideoCallStatus.calling &&
      invitee.status === VideoCallStatus.calling
    ) {
      console.info("Status update: No update received after calling!");
      dispatch(
        updateInvites({ list: [invitee], status: VideoCallStatus.unreachable })
      );
      handleVideoCallMissed({
        sendMessage,
        meeting_id,
        useruuid: invitee.useruuid,
      });
    } else if (
      status === VideoCallStatus.ringing &&
      invitee.status === VideoCallStatus.ringing
    ) {
      console.info("Status update: No update received after ringing!");
      dispatch(
        updateInvites({ list: [invitee], status: VideoCallStatus.missed })
      );
      handleVideoCallMissed({
        sendMessage,
        meeting_id,
        useruuid: invitee.useruuid,
      });
    }
  };

  /* call end handler */
  const onEndCall = async ({
    leaveCall = false,
    closeModal = false,
    endCall = false,
  } = {}) => {
    try {
      clearTimeout(autoCloseTimer);
      const endTime = moment().format(DateTimeFormat);
      // dispatch(updateMeetingInfo({ endTime }));
      setMeetingInfo(updateState({ endTime }));
      if (leaveCall) {
        console.info("Leaving video call session...", meeting_id);
        meetingManager?.leave();
      }
      if (closeModal) {
        console.info("Closing video call screen...", meeting_id);
        handleVideoCallClose({ dispatch });
      }
      if (endCall) {
        console.info("Ending video call...", meeting_id);
        handleVideoCallEnd({ dispatch, sendMessage, meeting_id });
      }
    } catch (error) {
      console.error("Error ending call...", error);
    }
  };

  /* participants change handler */
  const onAttendeeChange = (attendeeId, present, externalId, dropped) => {
    console.debug(
      `AttendeeChange: ${externalId}, Id: ${attendeeId}`,
      `Present: ${present}`
    );
    /**
     *  HOMESIGHT-3244: For screenshare start and stop this method is triggerd
     *  Here id will be like: a2cffc81-0eec-a235-6e9c-51d6029950a1#content
     */
    if (attendeeId?.includes("#")) return;
    const user = decodeExternalUserId(externalId);
    dispatch(
      updateInvites({
        list: [user],
        status: present ? VideoCallStatus.incall : VideoCallStatus.leaving,
      })
    );
    /* mute/volume status of participant */
    if (present) {
      audioVideo.realtimeSubscribeToVolumeIndicator(
        attendeeId,
        (id, volume, muted) => {
          if (muted != null) {
            console.debug(
              `Mute status changed for ${attendeeId}, isMute: ${muted}`
            );
            setParticipantsMuteStatus((s) => {
              const newState = { ...s };
              newState[id] = muted;
              return newState;
            });
          }
        }
      );
    } else {
      audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId, (id) => {
        console.log(`Unsubscribed from Audio status check for Id: ${id}`);
        setParticipantsMuteStatus((s) => {
          const newState = { ...s };
          delete newState[id];
          return newState;
        });
      });
    }
  };

  /** ===== api fetch functions ===== */
  const createSession = () => {
    return new Promise((resolve) => {
      if (meeting_id) {
        return resolve({ meeting_id });
      }
      const errorCallback = (error) => {
        console.error("Error creating new video call session:", error);
        message.error("Error creating new video call session!");
        resolve({});
      };
      const successCallback = (data) => {
        console.info("createSession result", data);
        dispatch(updateInviteStatus({ list: data?.contacts }));
        resolve(data);
      };
      try {
        const contacts = (callees ?? []).map((c) => {
          return { useruuid: c.useruuid };
        });
        videoCallApi.createSession(
          { createSession: { contacts, autoaccept } },
          apiCallback({
            translator: trans,
            failCallback: errorCallback,
            errorCallback,
            successCallback,
          })
        );
      } catch (error) {
        errorCallback(error);
      }
    });
  };

  const joinSession = (meeting_id) => {
    return new Promise((resolve, reject) => {
      const errorCallback = (error) => {
        console.error("Error joining video call session:", error);
        message.error("Error joining video call session!");
        resolve({});
      };
      const successCallback = (data) => {
        console.info("joinSession result", data);
        resolve(data);
      };
      try {
        videoCallApi.joinSession(
          { meeting_id },
          apiCallback({
            translator: trans,
            failCallback: errorCallback,
            errorCallback,
            successCallback,
          })
        );
      } catch (error) {
        errorCallback(error);
      }
    });
  };

  /* render */
  return (
    <VideoCallComponent
      header={header}
      isSetupDone={isSetupDone}
      callStatus={callStatus}
      onEndCall={onEndCall}
      meetingInfo={meetingInfo}
      participantsMuteStatus={participantsMuteStatus}
    />
  );
};

export default VideoCall;
