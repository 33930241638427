import React from "react";
import { ConnectedIcon, UnpairedIcon } from "../Common/Icons";

const NetworkingDetailsBlock = ({ device }) => {
  const labels = {
    wifi: {
      label1: device?.device_primary_wan,
      label2: [
        device?.device_wifi_ssid || "-",
        device.device_operating_frequency_band || "-",
        device.device_signal_strength
          ? `${device.device_signal_strength} dBm`
          : "-",
      ],
    },
    ethernet: {
      label1: device?.device_primary_wan,
      label2: ["10/100 Mbps"],
    },
    lte: {
      label1: device?.device_primary_wan,
      label2: [
        `IMEI : ${device?.device_imei || "-"}`,
        `SIM : ${device?.device_imsi || "-"}`,
        device?.device_carrier_id_name || "-",
      ],
    },
  };
  const { label1, label2 } = labels[
    device?.device_primary_wan?.toLowerCase()
  ] || {
    label1: "-",
    label2: "-",
  };
  const isConnected =
    device?.deviceconnectstatus?.toLowerCase() === "connected";

  return (
    <div className="networking-details-block-container">
      <div className="networking-details-block">
        <div className="networking-details-block-1">
          <div className="label-container-1">
            <span className="label-1">{label1}:</span>
          </div>
          <div className="label-container-2">
            {Array.isArray(label2) ? (
              label2.map((item, index) => (
                <span key={index} className="label-2">
                  {item}
                </span>
              ))
            ) : (
              <span className="label-2">{label2}</span>
            )}
          </div>
        </div>
        <div className="connectedicon-container">
          {isConnected ? (
            <ConnectedIcon />
          ) : (
            <UnpairedIcon label="Disconnected" />
          )}
        </div>
      </div>
    </div>
  );
};

export default NetworkingDetailsBlock;
