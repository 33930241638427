import React from "react";

export const ErrorIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 2.1665C5.40683 2.1665 1.66675 5.90658 1.66675 10.4998C1.66675 15.0931 5.40683 18.8332 10.0001 18.8332C14.5933 18.8332 18.3334 15.0931 18.3334 10.4998C18.3334 5.90658 14.5933 2.1665 10.0001 2.1665ZM10.0001 17.1169C6.34929 17.1169 3.38302 14.1506 3.38302 10.4998C3.38302 6.84904 6.33937 3.88277 10.0001 3.88277C13.6608 3.88277 16.6171 6.84904 16.6171 10.4998C16.6171 14.1506 13.6608 17.1169 10.0001 17.1169Z"
        fill="#F46363"
      />
      <path
        d="M9.94071 12.6429C10.3772 12.6429 10.7244 12.2858 10.7244 11.8592V6.75004C10.7244 6.31353 10.3673 5.96631 9.94071 5.96631C9.51413 5.96631 9.15698 6.32345 9.15698 6.75004V11.8592C9.15698 12.2957 9.51413 12.6429 9.94071 12.6429Z"
        fill="#F46363"
      />
      <path
        d="M10.6449 13.4959C10.5556 13.4165 10.4663 13.3471 10.3572 13.2975C10.2481 13.2578 10.129 13.228 10.01 13.228C9.89095 13.228 9.7719 13.2479 9.66277 13.2876C9.55365 13.3272 9.45444 13.3967 9.36515 13.486C9.27587 13.5653 9.21634 13.6645 9.16674 13.7737C9.11714 13.8828 9.0973 14.0018 9.10722 14.1209C9.10722 14.2399 9.10722 14.3689 9.16674 14.4879C9.21634 14.607 9.29571 14.7062 9.37507 14.7856C9.55365 14.9443 9.78182 15.0237 10.01 15.0237C10.2382 15.0237 10.4663 14.9443 10.635 14.7856C10.7342 14.7062 10.8036 14.5971 10.8433 14.4879C10.8929 14.3788 10.9029 14.2499 10.9029 14.1308C10.9029 14.0118 10.883 13.8927 10.8433 13.7836C10.7937 13.6745 10.7342 13.5752 10.6548 13.4959H10.6449Z"
        fill="#F46363"
      />
    </svg>
  );
};