/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useRef } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import calling from "../../../static/images/calling.gif";
import ringtone from "../../../static/audio/ringtone.mp3";
import { useWebSocketContext } from "../WebSocket/WebsocketContext";
import { getUserInfo } from "@commscopemycloud/humaui/Store/dataStore";
import {
  handleVideoCallAccept,
  handleVideoCallReject,
  handleVideoCallTimeout,
} from "@commscopemycloud/humaui/Services/VideoCallService";
import useUserInfo from "../Hooks/useUserInfo";
import CustomButton from "../Common/CustomButton";
import { formatFullNameDisplay } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import {
  PhotoResolution,
  VideoCallNotificationTimeout,
  VideoCallStatusUpdate,
  WSMessageTypes,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { CallDeclineIcon, CallIcon, ContactImage } from "../Common/Icons";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { updateNotificationMiniBanner } from "@commscopemycloud/humaui/Store/notificationStore";
import MissedCallDarkThemeNotiIcon from "../../../static/icons/desktop_missedcall_darknoti.svg";
import IncomingCallDarkThemeNotiIcon from "../../../static/icons/desktop_incomincall_darknoti.svg";
import IncomingCallLightThemeNotiIcon from "../../../static/icons/desktop_incomincall_lightnoti.svg";
import MissedCallLightThemeNotiIcon from "../../../static/icons/desktop_missedcall_lightnoti.svg";

const audio = new Audio(ringtone);
audio.loop = true;

const VideoCallNotificationModal = () => {
  const dispatch = useDispatch();
  const trans = useSelector(translator);
  const { sendMessage } = useWebSocketContext();
  const notification = useSelector(
    (state) => state.notification.videoCallNotification
  );
  const visible = useSelector(
    (state) => state.notification.videoCallNotificationModalVisible
  );
  // Use useRef for call notification instead of state
  const callNotificationRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  useUserInfo({ useruuid: notification?.useruuid });
  const caller = useSelector(getUserInfo(notification?.useruuid));
  const image = useMemo(
    () => caller?.profilePics && caller?.profilePics[PhotoResolution.R128],
    [caller]
  );

  const userInfo = useSelector((state) => state.data?.userInfo ?? {});
  const meeting_id = useMemo(() => notification?.meeting_id, [notification]);

  const getIconForNotification = (type) => {
    const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
      
    if (type === "incoming") {
      return isDarkMode ?  IncomingCallDarkThemeNotiIcon : IncomingCallLightThemeNotiIcon; // Use appropriate icons for incoming call
    } else if (type === "missed") {
      return isDarkMode ?  MissedCallDarkThemeNotiIcon : MissedCallLightThemeNotiIcon; // Use appropriate icons for missed call
    }
  };
    
  const showIncomingCallNotification = (notification) => {
    if (Notification.permission === "granted") {
      
      const userDetails = userInfo[notification?.useruuid] || {};
      const { firstname, lastname } = userDetails;
      const fullName = formatFullNameDisplay(firstname, lastname);
      const icon = getIconForNotification("incoming"); 
      // Get incoming call icon
      const notifyIncomeCall = new Notification('Incoming Call', {
        body: `${fullName}`,
        icon: icon,
        tag: 'incoming-call-notification',
        requireInteraction: true, 
        renotify: true, 
        silent: true,        
      });

      notifyIncomeCall.onclick = () => {
        if (!window.document.hasFocus()) {
          window.focus();
        }
      };     
      
      notifyIncomeCall.onclose = () => {
        console.log('Notification closed.');
      };
      // Store notification in ref
      callNotificationRef.current = notifyIncomeCall;
    }
  };
 
  const showMissedCallNotification = () => {
    const userDetails = userInfo[notification?.useruuid] || {};
    const { firstname, lastname } = userDetails;
    const fullName = formatFullNameDisplay(firstname, lastname);
    const icon = getIconForNotification("missed"); 
    const notifyMissedcall = new Notification('Missed Call', {
      body: `${fullName}`,
      icon: icon,
      tag: 'missed-call-notification',
      requireInteraction: true,
      renotify: true,
      silent: true
    });

    notifyMissedcall.onclick = () => {
      if (!window.document.hasFocus()) {
        window.focus();
      }
    };
    notifyMissedcall.onclose = () => {
      console.log('Notification closed.');
    };
  };

  useEffect(() => {
    let timer = null;
    if (visible) {
      try {
        audio?.play();
        showIncomingCallNotification(notification);
        timer = setTimeout(() => {
          handleVideoCallTimeout({ sendMessage, meeting_id, dispatch });
          dispatch(
            updateNotificationMiniBanner({
              message_type: WSMessageTypes.videoCallStatus,
              message: {
                meeting_id: meeting_id,
                call_status__status: VideoCallStatusUpdate.missed,
                event_originator_id: notification.useruuid,
                timestamp: new Date().toISOString(),
              },
            })
          );

          if (callNotificationRef.current) {
            callNotificationRef.current.close(); // Closing the active incoming call notification   
            callNotificationRef.current = null; // Reset ref to null 
          }

          // Show missed call notification
          showMissedCallNotification();

        }, VideoCallNotificationTimeout * 1000);
      } catch (error) {
        console.error("Calling Tune Error:", error);
      }
    }
    return () => {
      audio.pause();
      clearTimeout(timer);
    };
  }, [visible]);

  const handleAccept = () => {
    console.debug("Call Accepted");
    handleVideoCallAccept({ sendMessage, meeting_id, dispatch });
    if (callNotificationRef.current) {
      callNotificationRef.current.close();
      callNotificationRef.current.onclick(); // Closing the active incoming call notification
      callNotificationRef.current = null; // Reset ref to null
    }
  };

  const handleReject = () => {
    console.debug("Call Rejected");
    handleVideoCallReject({ sendMessage, meeting_id, dispatch });
    if (callNotificationRef.current) {
      callNotificationRef.current.close(); // Closing the active incoming call notification
      callNotificationRef.current = null; // Reset ref to null
    }
    setTimeout(() => {
      dispatch(
        updateNotificationMiniBanner({
          message_type: WSMessageTypes.videoCallStatus,
          message: {
            call_status__status: VideoCallStatusUpdate.declined,
            meeting_id: meeting_id,
            event_originator_id: notification.useruuid,
            timestamp: new Date().toISOString(),
          },
        })
      );
    }, 500);
  };

  return (
    <>
      {calling && (
        <Modal
          open={visible}
          closable={false}
          footer={null}
          maskClosable={true}
          width={null}
          wrapClassName="vertical-center-modal video-call-notification-modal"
        >
          <header>
            <span className="">{trans("INCOMINGCALL")}</span>
          </header>
          <div className="content">
            <div className="image-container">
              {!imageLoaded && (
                <ContactImage className="placeholder-profile-pic" />
              )}
              {image && (
                <img
                  src={image}
                  className={`user-profile-pic ${
                    imageLoaded ? "show" : "hide"
                  }`}
                  alt="pic"
                  onLoad={handleImageLoad}
                />
              )}
              {calling && <img className="calling-gif" src={calling} alt="" />}
            </div>
            <span className="name">
              {caller &&
                formatFullNameDisplay(caller?.firstname, caller?.lastname)}
            </span>
            <span className="relation">{caller?.relationship || " "}</span>
          </div>
          <footer>
            <CustomButton
              label={trans("DECLINE_U")}
              icon={<CallDeclineIcon className="decline-icon" />}
              onClick={handleReject}
              style={{ marginRight: "20px" }}
            />
            <CustomButton
              label={trans("ACCEPT_U")}
              icon={<CallIcon className="accept-icon" />}
              onClick={handleAccept}
            />
          </footer>
        </Modal>
      )}
    </>
  );
};

VideoCallNotificationModal.propTypes = {};

export default VideoCallNotificationModal;
