import React from "react";

export const RCVolumeDown = (props) => {
  const { className = "", style = {}, env = false, isSelected } = props;

  return (
    <svg
      className={`${className} ${isSelected ? "selected" : ""}`}
      style={style}
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_18891_160227)">
        <path
          d="M23.5 46.0027C11.0978 46.0027 1.01196 35.9028 1.01196 23.4994C1.01196 11.096 11.0978 0.996094 23.5 0.996094C35.9021 0.996094 45.988 11.096 45.988 23.4994C45.988 35.9028 35.9021 46.0027 23.5 46.0027Z"
          fill="#1C1C1C"
        />
        <path d="M23.5 1.9934C35.3512 1.9934 44.9761 11.6171 44.9761 23.5C44.9761 35.3829 35.3512 45.0066 23.5 45.0066C11.6488 45.0066 2.02392 35.3718 2.02392 23.5C2.02392 11.6282 11.6376 1.9934 23.5 1.9934ZM23.5 0C10.5469 0 0 10.5429 0 23.5C0 36.4571 10.5469 47 23.5 47C36.4531 47 47 36.4571 47 23.5C47 10.5429 36.4644 0 23.5 0Z" />
        <path
          d="M20.8239 18.7609C20.138 19.3589 19.5758 19.7355 19.5758 19.5804C19.5758 19.4254 18.8787 19.3146 18.0241 19.3146H16.0002C15.5842 19.3146 15.2356 19.7244 15.2356 20.2338V26.1697C15.2356 26.6681 15.5842 27.0889 16.0002 27.0889H18.0241C18.8787 27.0889 19.5758 27.0446 19.5758 26.9892C19.5758 26.9339 20.138 27.3879 20.8239 27.9859L24.3657 31.1089C25.0741 31.7291 26.0748 31.1311 26.0748 30.079V16.6678C26.0748 15.6158 25.0741 15.0178 24.3657 15.6379L20.8239 18.7609Z"
          fill="white"
        />
        <path
          d="M29.6055 25.4169C30.2801 24.8521 30.7186 24.0326 30.7186 23.0913C30.7186 22.1499 30.2801 21.3304 29.6055 20.7656"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18891_160227">
          <rect width="47" height="47" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
