/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Divider, Input, Spin, Tooltip, message } from "antd";
import {
  ContactIcon,
  ErrorIcon,
  NewCloseIcon,
  NewUpdateIcon,
} from "../Common/Icons";
import {
  Actions,
  PhotoResolution,
  RoleType,
  UserRoles,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import {
  formatFullNameDisplay,
  isCare,
  isManage,
  isSocial,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import useUserInfo from "../Hooks/useUserInfo";
import NewCustomButton, { ButtonTypes } from "../Common/NewCustomButton";
import Roles from "../Roles/Roles";
import { useCallback } from "react";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import "./Contacts.less";

const RequiredFields = {
  firstname: "First Name",
  lastname: "Last Name",
  email: "Email",
};

const DefaultValues = {
  firstname: "",
  lastname: "",
  email: "",
  sourcerelationship: "",
  targetrelationship: "",
};

const formatData = (data, isEditForm, role) => {
  const userObj = { rolename: role };
  Object.keys(DefaultValues).forEach((key) => (userObj[key] = data[key]));
  if (isEditForm) {
    userObj.useruuid = data.useruuid;
  }
  return userObj;
};

const ContactForm = (props) => {
  const { parentBread, hubRecord, record, action, onActionClick, contacts } =
    props;

  const [role, setRole] = useState("");
  const trans = useSelector(translator);
  const userApi = useSelector((state) => state.apis.userApi);
  const currentUser = useSelector(getCurrentUser);

  const isEditForm = action === Actions.editContact;
  const header = (parentBread || []).concat({
    label: `${isEditForm ? trans("EDIT") : trans("ADD")} ${trans("CONTACT")}`,
  });

  const currentRole = useMemo(
    () =>
      isManage(record)
        ? UserRoles.manage
        : isCare(record)
        ? UserRoles.care
        : isSocial(record)
        ? UserRoles.social
        : null,
    [record]
  );
  const [isValid, setIsValid] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [formData, setFormData] = useState(DefaultValues);
  const image = useMemo(
    () => hubRecord.profilePics && hubRecord.profilePics[PhotoResolution.R64],
    [hubRecord]
  );

  const [userInfo, fetchingUserInfo] = useUserInfo({
    useruuid: isEditForm && record?.useruuid,
  });
  const [emailError, setEmailError] = useState("");

  // useEffect to validate form whenever formData or role changes
  useEffect(() => {
    validate(false);
  }, [formData, role]);

  useEffect(() => {
    isEditForm &&
      setFormData({
        ...userInfo,
        sourcerelationship: record?.sourcerelationship,
        targetrelationship: record?.targetrelationship,
      });
  }, [userInfo, isEditForm, record]);

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    if (name === "email") {
      const emailPattern =
        /^(["a-zA-Z0-9.!#$%&()'*+/=?^_`{|}~\[\]-])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      const isValidEmail = emailPattern.test(value);
      // check if duplicate email is entered
      if (isValidEmail) {
        const duplicateEmail = contacts.some(
          (contact) =>
            contact?.email === value && contact?.email !== formData.email
        );
        setEmailError(
          duplicateEmail
            ? "Existing contact. Please enter a different email address."
            : ""
        );
      } else {
        setEmailError("Please enter a valid email address");
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const validate = (showError = true) => {
    const values = formData;
    let valid = true;
    let erroredFields = [];
    for (let field of Object.keys(RequiredFields)) {
      if (!values.hasOwnProperty(field) || !!!values[field]) {
        erroredFields.push(RequiredFields[field]);
        valid = false;
      }
    }
    // Check if a role is selected
    if (!role) {
      erroredFields.push("Role");
      valid = false;
    }
    if (showError && !valid) {
      let error = "Please fill required fields: " + erroredFields.join(", ");
      message.error(error, 10);
    }
    setIsValid(valid && !emailError);
    return valid && !emailError;
  };

  const handleSubmit = () => {
    const errorCallback = (error) => {
      setProcessing(false);
      const msg = `Error ${isEditForm ? "updating" : "adding"} contact`;
      console.error(msg, error);
      message.error(msg);
    };
    const successCallback = (data) => {
      setProcessing(false);
      const msg = `Contact ${isEditForm ? "updated" : "added"} successfully!`;
      console.info(msg, data);
      message.success(msg);
      onActionClick(
        isEditForm ? Actions.editContact : null,
        isEditForm ? record : null,
        true
      )();
    };
    try {
      // Trim leading and trailing whitespace from formData
      const trimmedFormData = {
        ...formData,
        firstname: formData.firstname.trim(),
        lastname: formData.lastname.trim(),
        sourcerelationship: formData?.sourcerelationship?.trim(),
        targetrelationship: formData?.targetrelationship?.trim(),
      };
      console.debug("Submit:", formData);
      const valid = validate();
      if (!valid) return;
      if (!isEditForm) {
        const duplicateEmail = contacts.some(
          (contact) => contact?.email === formData?.email
        );
        if (duplicateEmail) {
          message.error(trans("EMAILALREADYINUSEMSG"));
          return;
        }
      }
      setProcessing(true);
      const obj = formatData(trimmedFormData, isEditForm, role);
      userApi.inviteContactsToUser(
        hubRecord?.useruuid,
        obj,
        apiCallback({
          translator: trans,
          successCallback,
          errorCallback,
          failCallback: errorCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const handleRoleSelection = useCallback((role) => {
    setRole(role);
  }, []);

  // Render form item with label, input field, and error handling
  const renderFormItem = ({
    name,
    label,
    disabled,
    placeholder,
    className,
  }) => (
    <div className={`form-item form-item-verticle ${className ?? ""}`}>
      <label>{label}</label>
      <div
        className={`input-container ${
          name === "email" && emailError ? "error" : "default"
        }`}
      >
        <Input
          className="input-content"
          name={name}
          placeholder={placeholder}
          value={formData[name]}
          onChange={handleInputChange}
          disabled={disabled}
        />
      </div>
      <div className="error-text">
        {name === "email" && emailError && (
          <span className="error-message">
            <ErrorIcon className="error-icon" />
            {emailError}
          </span>
        )}
      </div>
    </div>
  );

  // Render role selection
  const renderRole = ({ label, className }) => {
    const isCurrentUserEditing =
      currentUser && currentUser.useruuid === record?.useruuid;
    return (
      <div className={`form-item form-item-verticle ${className ?? ""}`}>
        <label>{label}</label>
        <Roles
          contact={true}
          type={RoleType.user}
          onHandleSelection={handleRoleSelection}
          currentRole={currentRole}
          disabled={isCurrentUserEditing}
        />
      </div>
    );
  };

  // Main return JSX for the Contact Form component
  return (
    <div className="add-contact-container">
      <div className="add-contact-header">
        <div className="icon">
          <ContactIcon
            style={{ fill: "#113E57", width: "30px", height: "30px" }}
          />
        </div>
        <div className="header">
          {isEditForm ? "Edit Contact" : "Add Contact"}
        </div>
      </div>
      <Spin spinning={processing || fetchingUserInfo}>
        <div className="add-contact-block">
          <div className="relationship-container">
            <div className="label">Relationship</div>
            <div className="relationship-form-item">
              <div className="relationship-details">
                <div className="form-input-container">
                  {renderFormItem({
                    name: "sourcerelationship",
                    label: (
                      <>
                        <span>
                          <Tooltip
                            title={`${hubRecord?.firstname} ${hubRecord?.lastname}`}
                          >
                            <span>
                              {`${formatFullNameDisplay(
                                hubRecord?.firstname,
                                hubRecord?.lastname
                              )}'s `}
                            </span>
                          </Tooltip>
                          relationship with the new Contact
                        </span>
                      </>
                    ),
                    placeholder: "Enter relationship with the new contact",
                    // placeholder: "Friend, sister, brother, neighbor, etc.",
                  })}
                </div>
              </div>
            </div>
          </div>
          <Divider className="divider-style" />
          <div className="contact-container">
            <div className="new-contact-container">
              <div className="label">New Contact</div>
              <div className="new-contact-form-item">
                <div className="details-1">
                  <div className="form-input-container">
                    {renderFormItem({
                      name: "firstname",
                      label: `${trans("FIRSTNAME")}*`,
                      placeholder: "Enter first name",
                      disabled: isEditForm,
                    })}
                  </div>
                  <div className="form-input-container">
                    {renderFormItem({
                      name: "lastname",
                      label: `${trans("LASTNAME")}*`,
                      placeholder: "Enter last name",
                      disabled: isEditForm,
                    })}
                  </div>
                </div>
                <div className="details-1">
                  <div className="form-input-container">
                    {renderFormItem({
                      name: "targetrelationship",
                      label: (
                        <>
                          <span>
                            {`New Contact’s relationship with `}
                            <Tooltip
                              title={`${hubRecord?.firstname} ${hubRecord?.lastname}`}
                            >
                              <span>
                                {formatFullNameDisplay(
                                  hubRecord?.firstname,
                                  hubRecord?.lastname
                                )}
                              </span>
                            </Tooltip>
                          </span>
                        </>
                      ),
                      placeholder: "Enter new contact relationship",
                      // placeholder: "Friend, sister, brother, neighbor, etc.",
                    })}
                  </div>
                  <div className="form-input-container">
                    {renderFormItem({
                      name: "email",
                      label: `${trans("EMAIL")}*`,
                      placeholder: "Enter email address",
                      disabled: isEditForm,
                    })}
                  </div>
                </div>
                <div className="role-container-1">
                  <div className="label">
                    <span>
                      {` What role does this person have with `}
                      <Tooltip
                        title={`${hubRecord?.firstname} ${hubRecord?.lastname}`}
                      >
                        <span>
                          {formatFullNameDisplay(
                            hubRecord?.firstname,
                            hubRecord?.lastname
                          )}
                        </span>
                      </Tooltip>
                      ?*
                    </span>
                  </div>
                  <div className="roles-list">{renderRole({})}</div>
                </div>
                <div className="buttons-container">
                  <div className="cancel-button">
                    <NewCustomButton
                      label={trans("CANCEL")}
                      icon={<NewCloseIcon className="cancel-icon" />}
                      onClick={onActionClick(null, null)}
                    />
                  </div>
                  <div className="update-button">
                    <NewCustomButton
                      type={ButtonTypes.primary}
                      label={trans("UPDATE")}
                      icon={
                        <NewUpdateIcon
                          className={
                            isValid
                              ? "update-icon-enabled"
                              : "update-icon-disabled"
                          }
                        />
                      }
                      onClick={handleSubmit}
                      disabled={!isValid}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

ContactForm.propTypes = {
  parentBread: PropTypes.arrayOf(PropTypes.object),
  hubRecord: PropTypes.object,
  record: PropTypes.object,
  action: PropTypes.string,
  onActionClick: PropTypes.func,
};

export default ContactForm;
