import React, { useEffect, useMemo, useState } from "react";
import "./DeviceInventory.less";
import {
  DeviceInventoryIcon,
  EditNewIcon,
  NewLocationIcon,
  NewDeviceIcon,
  LearnMoreIcon,
  DevicesNewIcon,
} from "../Common/Icons";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import DeviceInventoryList from "./DeviceInventoryList";
import DeviceInventoryModal from "./DeviceInventoryModal";
import { message, Spin } from "antd";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

const ALLOWED_MODELS = ["RC300", "RC400", "EM200", "HC200"];
const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getDeviceInfo = (device) => {
  const deviceInfoMap = {
    EM200: [
      {
        key: "Location Name",
        value: device?.device_location || "-",
      },
      {
        key: "Serial Number",
        value: device?.device_serial_number || "-",
      },
      { key: "SW Version", value: device?.device_software_version || "-" },
      {
        key: "Signal Level",
        value: device?.device_signal_strength
          ? `${device.device_signal_strength} dBm`
          : "-",
      },
    ],
    HC200: [
      {
        key: "Location Name",
        value: device?.location || "-",
      },
      {
        key: "Serial Number",
        value: device?.device_serial_number || "-",
      },
      {
        key: "Model",
        value: device?.modelnumber || "-",
      },
    ],
  };

  const rcModels = ["RC300", "RC400"];
  if (rcModels.includes(device?.modelnumber)) {
    return [
      { key: "Model", value: device?.device_name || "-" },
      { key: "SW Version", value: device?.device_firmware_version || "-" },
      { key: "Signal level", value: device?.device_signal_level || "-" },
      { key: "MAC Address", value: device?.device_mac_address || "-" },
    ];
  }

  return deviceInfoMap[device?.modelnumber] || [];
};

const mapEventData = (device) => {
  if (device.device_name === "RC400" || device.device_name === "RC300") {
    return {
      connectionStatus:
        device?.device_connection_status?.toUpperCase() || "DISCONNECTED",
    };
  } else {
    return {
      connectionStatus: device?.device_connected ? "CONNECTED" : "DISCONNECTED",
    };
  }
};

const useFetchUserAccessories = (userEventsApi, hubRecord, trans) => {
  const [accessoriesData, setAccessoriesData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const fetchUserAccessories = () => {
      const errorCallback = (error) => console.error(error);
      const successCallback = (data) => {
        setAccessoriesData(data["user_accessories"]);
        setDataFetched(true);
      };

      try {
        userEventsApi.getUserAccessories(
          hubRecord.useruuid,
          { eventtypes: ["environment_status"] },
          apiCallback({
            translator: trans,
            failCallback: errorCallback,
            errorCallback,
            successCallback,
          })
        );
      } catch (error) {
        errorCallback(error);
      }
    };

    fetchUserAccessories();
  }, [userEventsApi, hubRecord, trans]);

  return { accessoriesData, dataFetched };
};

const actionsData = (device) => {
  if (device?.modelnumber === "HC200") {
    return {
      reboot: {
        title: "Refresh",
        description: (
          <div>
            <span className="custom-hint-label">Hint : </span>HC200 will
            immediately restart
          </div>
        ),
        action: "device_reboot",
      },
      reset: {
        title: "Factory Reset",
        description: (
          <div>
            <span className="custom-hint-label">Hint : </span> HC200 will
            immediately clear all user data and restore factory defaults and
            restart,
            <span style={{ fontSize: "12px" }}>
              <br /> Warning: If any EM200s are paired with this HC200, use the
              UNPAIR EM200 action first
              <br />
              (To manually unpair an EM200 that was associated with an HC200
              that was factory reset remove and
              <br /> reinsert the EM200 batteries 3 times within 10 seconds each
              time)
            </span>
          </div>
        ),
        action: "factory_reset",
      },
      check_for_updates: {
        title: "Check for Updates",
        description: (
          <div>
            <span className="custom-hint-label">Hint : </span>HC200 will check
            for any updates and immediately download and install
          </div>
        ),
        action: "perform_checkforupdates",
      },
    };
  }

  if (device?.modelnumber === "EM200") {
    return {
      unpair: {
        title: "Unpair",
        description: (
          <div>
            <span className="custom-hint-label">Hint : </span> Unpairs EM200
            Device
          </div>
        ),
        action: "perform_unPairAccessories",
        message: "Signal sent to device to unpair EM200",
      },
    };
  }

  if (device?.modelnumber === "RC400" || device?.modelnumber === "RC300") {
    return {
      unpair: {
        title: "Unpair",
        description: (
          <div>
            <span className="custom-hint-label">Hint : </span> Unpairs the
            Remote Control
          </div>
        ),
        action: "perform_unPairRCU",
        message: "Signal sent to device to unpair RCU",
      },
      find_me: {
        title: "Find me",
        description: (
          <div>
            <span className="custom-hint-label">Hint : </span>Subscriber should
            hear a tone on their Remote Control and they can press any key on
            the Remote to the Alert.
          </div>
        ),
        action: "perform_findRCU",
        message: "Find Remote Alert Sent",
      },
    };
  }
  return {};
};

const performAction = (
  deviceManagementApi,
  data,
  providerid,
  trans,
  params
) => {
  const errorCallback = (error) => {
    console.log(error);
    message.error("Error performing: " + data.title + " " + error.message);
  };
  const successCallback = (info) => {
    console.log("Device data", info);
    if (data.message) {
      message.success(data.message);
    } else {
      message.success("Signal sent to Device for: " + data.title);
    }
  };

  try {
    deviceManagementApi.performCommand(
      data.action,
      providerid,
      data.deviceUuid,
      params ? { source: "System", ...params } : { source: "System" },
      apiCallback({
        translator: trans,
        failCallback: errorCallback,
        errorCallback,
        successCallback,
      })
    );
  } catch (error) {
    errorCallback(error);
  }
};

const DeviceInventoryTabs = ({
  activeTab,
  setActiveTab,
  filteredAccessoriesData,
}) => (
  <div className="device-tabs-container">
    <div className="device-tabs">
      {["All", "Paired", "Unpaired"].map((tab) => (
        <div className="tab-wrapper" key={tab}>
          <div
            className={`device-tab-label ${activeTab === tab ? "active" : ""}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}{" "}
            {tab === "All"
              ? `(${
                  filteredAccessoriesData.filter(
                    (item) => item?.modelnumber !== "HC200"
                  ).length
                })`
              : ""}
            {tab === "Paired"
              ? `(${
                  filteredAccessoriesData.filter(
                    (item) =>
                      item?.modelnumber !== "HC200" &&
                      item?.paired_status === "PAIRED"
                  ).length
                })`
              : ""}
            {tab === "Unpaired"
              ? `(${
                  filteredAccessoriesData.filter(
                    (item) =>
                      item?.modelnumber !== "HC200" &&
                      item?.paired_status === "UNPAIRED"
                  ).length
                })`
              : ""}
          </div>
        </div>
      ))}
    </div>
  </div>
);

const DeviceInventory = ({
  hubRecord,
  timezone,
  deviceStatus,
  providerid,
  loginUserTimeformat,
}) => {
  const trans = useSelector(translator);
  const userEventsApi = useSelector((state) => state.apis.userEventsApi);
  const deviceManagementApi = useSelector(
    (state) => state.apis.deviceManagementApi
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [activeTab, setActiveTab] = useState("All");

  const videoCallNotificationModalVisible = useSelector(
    (state) => state.notification.videoCallNotificationModalVisible
  );

  useEffect(() => {
    if (videoCallNotificationModalVisible) {
      handleModalClose();
    }
  }, [videoCallNotificationModalVisible]);

  const { accessoriesData, dataFetched } = useFetchUserAccessories(
    userEventsApi,
    hubRecord,
    trans
  );

  const filteredAccessoriesData = useMemo(() => {
    return accessoriesData
      .filter((device) => ALLOWED_MODELS.includes(device?.modelnumber))
      .sort((a, b) => {
        const order = { HC200: 1, RC400: 2, RC300: 3, EM200: 4 };
        return (order[a.modelnumber] || 5) - (order[b.modelnumber] || 5);
      });
  }, [accessoriesData]);

  const filteredByTab = filteredAccessoriesData.filter((device) => {
    if (activeTab === "All") return true;
    if (activeTab === "Paired") {
      return (
        device?.paired_status === "PAIRED" || device?.paired_status === null
      );
    }
    if (activeTab === "Unpaired") {
      return (
        device?.paired_status === "UNPAIRED" || device?.paired_status === null
      );
    }
    return true;
  });

  const showModal = (device) => {
    setSelectedDevice(device);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedDevice(null);
  };

  return (
    <div style={{ padding: "40px 40px 0px 40px" }}>
      <div className="device-header-container">
        <div className="device-header-icon-title">
          <div className="device-header-icon">
            <DeviceInventoryIcon
              style={{ fill: "#113E57", width: "29px", height: "32px" }}
            />
          </div>
          <div className="device-header-title">{trans("DEVICES")}</div>
        </div>
        <DeviceInventoryTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          filteredAccessoriesData={filteredAccessoriesData}
        />
      </div>
      <Spin spinning={!dataFetched} style={{ margin: "70px -20px" }}>
        {dataFetched && (
          <>
            <div style={{ padding: "16px" }}>
              {filteredByTab.length === 0 ? (
                <div className="no-devices-container">
                  <div className="no-devices-card">
                    <div className="no-devices-info">
                      <div className="label">
                        {activeTab === "Paired"
                          ? "You don’t have any device paired yet."
                          : activeTab === "Unpaired"
                          ? "You don’t have any device unpaired yet."
                          : "You don’t have any device yet."}
                      </div>
                      {/* <div className="description">
                        Vorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc vulputate libero et velit interdum, ac aliquet odio
                        mattis.
                      </div> */}
                    </div>
                    {/* <div className="learn-more-container">
                      <LearnMoreIcon />
                    </div> */}
                  </div>
                </div>
              ) : (
                filteredByTab.map((device, index) => {
                  const isExpanded = device?.modelnumber === "HC200";
                  const key = device?.deviceId || index;

                  return (
                    <DeviceInventoryList
                      key={key}
                      device={device}
                      index={index}
                      isExpanded={isExpanded}
                      showModal={showModal}
                      deviceStatus={deviceStatus}
                      getDeviceInfo={getDeviceInfo}
                      timezone={
                        timezone ? timezone?.split(" ")[0] : LOCAL_TIMEZONE
                      }
                      connectionStatus={mapEventData(device).connectionStatus}
                    />
                  );
                })
              )}
            </div>
          </>
        )}
      </Spin>
      {isModalVisible && (
        <DeviceInventoryModal
          isModalVisible={isModalVisible}
          handleOk={handleModalClose}
          handleCancel={handleModalClose}
          device={selectedDevice}
          selectedDeviceInfo={getDeviceInfo(selectedDevice)}
          deviceStatus={deviceStatus}
          timezone={timezone ? timezone?.split(" ")[0] : LOCAL_TIMEZONE}
          connectionStatus={mapEventData(selectedDevice).connectionStatus}
          hubRecord={hubRecord}
          performAction={(data, params) =>
            performAction(deviceManagementApi, data, providerid, trans, params)
          }
          actionsData={actionsData(selectedDevice)}
          loginUserTimeformat={loginUserTimeformat}
          accessoriesData={accessoriesData}
        />
      )}
    </div>
  );
};

export default DeviceInventory;
