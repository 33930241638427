import React from "react";

export const RCHome = (props) => {
  const { className = "", style = {}, env = false, isSelected } = props;

  return (
    <svg
      className={`${className} ${isSelected ? "selected" : ""}`}
      style={style}
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_18891_160223)">
        <path
          d="M23.5002 46.0027C11.0981 46.0027 1.01221 35.9028 1.01221 23.4994C1.01221 11.096 11.0981 0.996094 23.5002 0.996094C35.9024 0.996094 45.9883 11.096 45.9883 23.4994C45.9883 35.9028 35.9024 46.0027 23.5002 46.0027Z"
          fill="#005D98"
        />
        <path
          d="M23.365 16.3575L34.1255 19.8902V29.0267H12.9755V19.8902L23.365 16.3575ZM23.365 13.0352C22.9939 13.0352 22.6229 13.0905 22.2631 13.2123L11.8736 16.7451C10.5131 17.2102 9.60229 18.4727 9.60229 19.8902V29.0267C9.60229 30.865 11.109 32.349 12.9755 32.349H34.1255C35.992 32.349 37.4987 30.865 37.4987 29.0267V19.8902C37.4987 18.4616 36.5655 17.1881 35.1937 16.734L24.4332 13.2013C24.0846 13.0905 23.7248 13.0352 23.365 13.0352Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_18891_160223">
          <rect width="47" height="47" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
