import React from "react";

export const RCBack = (props) => {
  const { className = "", style = {}, env = false, isSelected } = props;

  return (
    <svg
      className={`${className} ${isSelected ? "selected" : ""}`}
      style={style}
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_18891_160233)">
        <path
          d="M23.5 46.0027C11.0978 46.0027 1.01196 35.9028 1.01196 23.4994C1.01196 11.096 11.0978 0.996094 23.5 0.996094C35.9021 0.996094 45.988 11.096 45.988 23.4994C45.988 35.9028 35.9021 46.0027 23.5 46.0027Z"
          fill="#005D98"
        />
        <path
          d="M20.2843 31.353L12.3123 23.5012L20.2843 15.6494C21.0264 14.9185 22.2295 14.9295 22.9491 15.6826C23.6462 16.4024 23.635 17.5431 22.9154 18.2408L19.441 21.6628H32.8326C33.867 21.6628 34.6991 22.4823 34.6991 23.5012C34.6991 24.52 33.867 25.3395 32.8326 25.3395H19.441L22.9154 28.7615C23.635 29.4703 23.6462 30.5999 22.9491 31.3197C22.2295 32.0728 21.0151 32.0839 20.2843 31.353Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_18891_160233">
          <rect width="47" height="47" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
