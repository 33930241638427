import {
  CallBusyStatus,
  WSMessageTypes,
  WSNotificationTypes,
  DefaultLanguage,
} from "@commscopemycloud/humaui/Utilities/Constants";
import {
  handleVideoCallBusy,
  handleVideoCallReceive,
  handleCallStatusUpdate,
  handleVideoCallClose,
} from "@commscopemycloud/humaui/Services/VideoCallService";
import { unzipMessage } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { updateNotificationMiniBanner } from "@commscopemycloud/humaui/Store/notificationStore";
import {
  showContactNotification,
  showScheduleNotification,
} from "../Notification/DesktopNotificationManager";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";

export const processMessage = ({
  dispatch,
  message,
  sendMessage,
  meetingInfo,
  videoCallModalVisible,
  videoCallNotificationModalVisible,
  currentUser,
  resourceMap,
}) => {
  try {
    const { payload } = message;
    const origMessage = unzipMessage(payload);
    const jsonMessage = JSON.parse(origMessage);
    console.info("message:", jsonMessage);
    const messageType = jsonMessage?.message_type;
    const messageObj = jsonMessage[messageType];
    if (messageType === WSMessageTypes.videoCallStatus) {
      console.debug("Websocket message: call status update...");
      handleCallStatusUpdate({ sendMessage, dispatch, message: messageObj });
    } else if (messageType === WSMessageTypes.notification) {
      console.debug("Websocket message: notification...");
      const { notification_type } = messageObj;
      if (notification_type === WSNotificationTypes.videoCall) {
        console.debug("Websocket message: video call notification...");
        handleVideoCallNotification({
          messageObj,
          meetingInfo,
          videoCallModalVisible,
          videoCallNotificationModalVisible,
          dispatch,
          sendMessage,
        });
      } else {
        console.warn("No handler for notification type:", notification_type);
      }
    } else if (messageType === WSMessageTypes.calendar) {
      if (
        messageObj.schedule_event?.action === "delete" ||
        messageObj.schedule_event?.action === "modified"
      )
        return;
      showScheduleNotification(jsonMessage.calendar);
      dispatch(
        updateNotificationMiniBanner({
          message_type: WSMessageTypes.calendar,
          message: jsonMessage.calendar,
        })
      );
    } else if (messageType === WSMessageTypes.contact) {
      if (
        messageObj?.action === "deleted" ||
        messageObj?.action?.toLowerCase() === "modified"
      )
        return;
      showContactNotification(jsonMessage.contact, currentUser, resourceMap);
      dispatch(
        updateNotificationMiniBanner({
          message_type: WSMessageTypes.contact,
          message: jsonMessage.contact,
        })
      );
    } else {
      console.warn("No message handler for type:", messageType);
    }
  } catch (error) {
    console.error("Error processing websocket message:", error);
  }
};

const handleVideoCallNotification = ({
  messageObj,
  meetingInfo,
  videoCallModalVisible,
  videoCallNotificationModalVisible,
  dispatch,
  sendMessage,
}) => {
  const { meeting_id, callStatus } = meetingInfo ?? {};
  if (
    CallBusyStatus.includes(callStatus) ||
    videoCallNotificationModalVisible
  ) {
    handleVideoCallBusy({ message: messageObj, sendMessage });
  } else {
    if (videoCallModalVisible) {
      handleVideoCallClose({ meeting_id, sendMessage, dispatch });
    }
    handleVideoCallReceive({ dispatch, message: messageObj, sendMessage });
  }
};

export const checkForMaintenance = async (
  userInfo,
  setAnnouncement,
  maintenanceApi,
  trans
) => {
  const errorCallback = (error) => {
    console.error("Error fetching maintenance announcement:", error);
  };
  const successCallback = (data) => {
    console.info("Maintenance announcement:", data);
    if (!data || !Object.keys(data).length) {
      return setAnnouncement({ type: null, message: null });
    }

    const isAnnouncement = Boolean(data.announcement?.message);
    const announcement = {
      type: isAnnouncement ? "Announcement" : "Information",
      message: isAnnouncement
        ? data.announcement.message
        : data.information?.message,
    };

    setAnnouncement(announcement);
  };
  try {
    const locale = userInfo?.locale || DefaultLanguage;
    maintenanceApi.getAnnouncementByLocale(
      locale,
      apiCallback({
        translator: trans,
        failCallback: errorCallback,
        errorCallback,
        successCallback,
      })
    );
  } catch (error) {
    errorCallback(error);
  }
};
