import React from "react";

export const NewLocationIcon = (props) => {
  const { className = "", style = {}, disabled = false, onClick } = props;
  return (
    <svg
      className={className}
      style={style}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0129 4.59986C18.921 4.59986 22.0941 7.77298 22.0941 11.6811C22.0941 13.3843 21.4058 14.8892 20.4842 16.1841C18.9677 18.2956 15.0129 25.0268 15.0129 25.0268C15.0129 25.0268 11.0465 18.2956 9.54163 16.1841C8.62002 14.9008 7.93173 13.3959 7.93173 11.6811C7.92007 7.77298 11.0932 4.59986 15.0129 4.59986ZM15.0129 15.1575C17.2644 15.1575 19.0843 13.3609 19.0843 11.1444C19.0843 8.9279 17.2644 7.13136 15.0129 7.13136C12.7614 7.13136 10.9415 8.9279 10.9415 11.1444C10.9415 13.3609 12.7614 15.1575 15.0129 15.1575ZM15.0129 2.5C9.9266 2.5 5.79688 6.62972 5.79688 11.7161C5.79688 13.6643 6.4735 15.6008 7.81507 17.4673C9.34331 19.6022 13.4497 26.5667 13.4847 26.6367C13.7997 27.1734 14.3713 27.5 15.0013 27.5C15.6312 27.5 16.2028 27.1734 16.5178 26.6367C16.5645 26.5667 20.6592 19.6022 22.1874 17.4673C23.529 15.5891 24.2056 13.6526 24.2056 11.7161C24.2173 6.62972 20.0876 2.5 15.0129 2.5ZM15.0129 13.3959C13.7413 13.3959 12.7031 12.381 12.7031 11.1328C12.7031 9.88451 13.7413 8.86958 15.0129 8.86958C16.2845 8.86958 17.3228 9.88451 17.3228 11.1328C17.3228 12.381 16.2845 13.3959 15.0129 13.3959Z"
        fill="#113E57"
      />
    </svg>
  );
};
