import moment from "moment";
import ScheduleMeetingDarkThemNotiIcon from "../../../static/icons/deskstop_schedule_darknoti.svg";
import ScheduleMeetingLightThemNotiIcon from "../../../static/icons/deskstop_schedule_lightnoti.svg";
import ContactNotiIcon from "../../../static/icons/desktop_contactimg_notif.svg";
import { getProfilePicUrl } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { PhotoResolution } from "@commscopemycloud/humaui/Utilities/Constants";

const getIconForNotification = (type) => {
  const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
  if (type === "schedule") {
    return isDarkMode ?  ScheduleMeetingDarkThemNotiIcon : ScheduleMeetingLightThemNotiIcon; // Use appropriate icons for missed call
  }
};

export const showContactNotification = (contact, currentUser, resourceMap) => {
  if (Notification.permission === "granted") {
    const { firstname, lastname } = contact;
    const contactFullName = (`${firstname} ${lastname}`);
    const currentUserFullName = (currentUser?.name);
    const userPhoto = getProfilePicUrl(
      currentUser?.useruuid,
      resourceMap,
      PhotoResolution.R64
    );
    
    const notifyContact = new Notification('New Contact Added', {
      body: `${contactFullName}\n${currentUserFullName}`,
      icon: userPhoto || ContactNotiIcon,
      tag: 'deskstop-contact-notification',
      requireInteraction: false,
      renotify: true,
      silent: true
    });
    notifyContact.onclick = () => {
      if (!window.document.hasFocus()) {
        window.focus();
      }
    };
    notifyContact.onclose = () => {
      console.log('Notification closed.');
    };
  }
};

export const showScheduleNotification = (event) => {
  if (Notification.permission === "granted") {
    const scheduleEvent = event.schedule_event;

    if (!scheduleEvent) {
      console.error("Invalid event data: schedule_event is missing");
      return;
    }

    let eventname = scheduleEvent.event_name;
    let eventdetails = "";
    const separator = ", ";

    if (scheduleEvent.event_timestamp) {
      const timestamp = Number(scheduleEvent.event_timestamp);
      const eventDate = moment(timestamp);

      if (!eventDate.isValid()) {
        console.error("Invalid event date:", eventDate);
        return;
      }

      const today = moment().startOf('day');
      const tomorrow = moment().add(1, 'days').startOf('day');
      const weekAhead = moment().add(7, 'days').endOf('day');

      // Format event time based on how far in the future it is
      if (eventDate.isSame(today, 'day')) {
        eventdetails = `Today ${eventDate.format("h:mm A")}`;
      } else if (eventDate.isSame(tomorrow, 'day')) {
        eventdetails = `Tomorrow ${eventDate.format("h:mm A")}`;
      } else if (eventDate.isBefore(weekAhead) && eventDate.isAfter(today)) {
        eventdetails = `${eventDate.format("ddd")} ${eventDate.format("h:mm A")}`;
      } else {
        eventdetails = `${eventDate.format("MMMM D")} ${eventDate.format("h:mm A")}`;
      }
    } else if (scheduleEvent.recurring) {
      // Recurring event logic with timezone adjustment
      const recurringTime = moment
        .utc(scheduleEvent.event_time, "HH:mm") // Parse in UTC
        .local(); // Convert to local timezone

      let recurringDays = scheduleEvent.schedule?.days_of_week || [];

      const capitalizedDays = recurringDays.map(day =>
        day.charAt(0).toUpperCase() + day.slice(1).toLowerCase()
      );
      const recurringDaysStr = capitalizedDays.join(separator);
      eventdetails = `Every ${recurringDaysStr} ${recurringTime.format("h:mm A")}`;
    }
    const icon = getIconForNotification("schedule"); 
    const notifySchedule = new Notification('New Scheduled Call', {
      body: `${eventname}\n${eventdetails}`,
      icon: icon,
      tag: 'deskstop-schedule-notification',
      requireInteraction: false,
      renotify: true,
      silent: true
    });

    notifySchedule.onclick = () => {
      if (!window.document.hasFocus()) {
        window.focus();
      }
    };
    notifySchedule.onclose = () => {
      console.log('Notification closed.');
    };
  }
};
