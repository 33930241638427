import React from "react";

export const FindMeIcon = (props) => {
    const { className = "", style = {} } = props;
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_18582_9424)">
                <path d="M11.1111 3.20117H12.8889C13.3798 3.20117 13.7778 3.59915 13.7778 4.09006V4.59565C13.7778 4.97592 14.033 5.30628 14.3842 5.45209C14.7356 5.59795 15.1446 5.54236 15.4136 5.27338L15.7712 4.91576C16.1183 4.56862 16.6812 4.56862 17.0283 4.91576L18.2853 6.17283C18.6325 6.51997 18.6324 7.08278 18.2853 7.42992L17.9278 7.78748C17.6588 8.05649 17.6032 8.46559 17.7491 8.81694C17.8948 9.16817 18.2252 9.42339 18.6056 9.42339H19.1111C19.602 9.42339 20 9.82135 20 10.3123V12.0901C20 12.581 19.602 12.9789 19.1111 12.9789H18.6055C18.2252 12.9789 17.8949 13.2342 17.7491 13.5854C17.6032 13.9367 17.6588 14.3458 17.9278 14.6148L18.2853 14.9724C18.6325 15.3195 18.6325 15.8823 18.2853 16.2294L17.0283 17.4865C16.6812 17.8337 16.1183 17.8337 15.7712 17.4865L15.4136 17.129C15.1446 16.8599 14.7356 16.8044 14.3842 16.9502C14.033 17.0961 13.7778 17.4264 13.7778 17.8067V18.3123C13.7778 18.8032 13.3798 19.2012 12.8889 19.2012H11.1111C10.6202 19.2012 10.2222 18.8032 10.2222 18.3123V17.8068C10.2222 17.4264 9.967 17.096 9.61577 16.9502C9.26442 16.8044 8.85532 16.8599 8.58631 17.129L8.22873 17.4865C7.88159 17.8337 7.31878 17.8337 6.97165 17.4865L5.71457 16.2294C5.36744 15.8823 5.36744 15.3195 5.71457 14.9724L6.0722 14.6148C6.34119 14.3458 6.39678 13.9367 6.25092 13.5854C6.10511 13.2342 5.77475 12.9789 5.39448 12.9789H4.88889C4.39797 12.9789 4 12.581 4 12.0901V10.3123C4 9.82135 4.39797 9.42339 4.88889 9.42339H5.39446C5.77474 9.42339 6.10511 9.16818 6.25092 8.81696C6.3968 8.46562 6.34121 8.05654 6.0722 7.78754L5.71459 7.42992C5.36745 7.08278 5.36745 6.51997 5.71459 6.17284L6.97166 4.91576C7.3188 4.56863 7.88161 4.56863 8.22875 4.91576L8.58636 5.27338C8.85536 5.54238 9.26444 5.59797 9.61579 5.4521C9.967 5.30628 10.2222 4.97591 10.2222 4.59563V4.09006C10.2222 3.59915 10.6202 3.20117 11.1111 3.20117Z" stroke="#005D98" strokeWidth="1.5" />
                <path d="M13.7782 11.2016C13.7782 12.1835 12.9823 12.9794 12.0004 12.9794C11.0186 12.9794 10.2227 12.1835 10.2227 11.2016C10.2227 10.2197 11.0186 9.42383 12.0004 9.42383C12.9823 9.42383 13.7782 10.2197 13.7782 11.2016Z" stroke="#005D98" strokeWidth="1.5" />
            </g>
            <defs>
                <clipPath id="clip0_18582_9424">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
